import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "introducing-effectts"
    }}>{`Introducing EffectTS`}</h1>
    <h5 {...{
      "id": "february-6-2025--dkas-office"
    }}>{`February 6, 2025 | `}<a parentName="h5" {...{
        "href": "https://dka.io/"
      }}>{`DK&A's Office`}</a></h5>
    <h5 {...{
      "id": "february-13-2025--wrocław-typescript"
    }}>{`February 13, 2025 | `}<a parentName="h5" {...{
        "href": "https://github.com/WrocTypeScript/"
      }}>{`Wrocław TypeScript`}</a></h5>
    <hr></hr>
    <ul>
      <li parentName="ul">{`📊 `}<strong parentName="li">{`Demo`}</strong>{`: `}<a parentName="li" {...{
          "href": "https://github.com/kajetansw/effect-ts-demo"
        }}>{`https://github.com/kajetansw/effect-ts-demo`}</a></li>
      <li parentName="ul">{`👨‍💻 `}<strong parentName="li">{`Form`}</strong>{`: talk + live coding`}</li>
    </ul>
    <hr></hr>
    <p>{`This talk served to introduce the audience to the next-gen solution for functional programming in TypeScript - `}<a parentName="p" {...{
        "href": "https://effect.website/"
      }}>{`EffectTS`}</a>{`. This in-depth introduction covered: the `}<inlineCode parentName="p">{`Effect`}</inlineCode>{` type, how to create Effects, how to compose Effects (pipes and generators), example on how to translate existing API to Effect domain, example of useful utils.`}</p>
    <p>{`Coming from `}<a parentName="p" {...{
        "href": "https://effect.website/docs/additional-resources/effect-vs-fp-ts/"
      }}>{`fp-ts`}</a>{` or other functional programming libraries? You'll definitely find something interesting diving into EffectTS!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      